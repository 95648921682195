import React, { useState } from "react";
import logo from "../../assets/LEFTKOAST-LOGO.png";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./SignUp.css";

function SignUp() {
  const production = useSelector((state) => state.production.production);
  const [capVal, setCapVal] = useState(null);
  const testingURL = "http://127.0.0.1:5000/register";
  const productionURL = "https://lkp-api.vercel.app/register";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    user_role: "USER", // Default user role
    phone_number: "",
    _password_hash: "",
  };

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    user_role: Yup.string()
      .oneOf(["USER", "AUTHOR"], "Invalid role")
      .required("User Role is required"),
    phone_number: Yup.string().required("Phone Number is required"),
    _password_hash: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(production ? productionURL : testingURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          dispatch(setSuccessMessage("Registration successful!"));
          navigate("/signin");
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
        // Optionally handle error state here
      }
    },
  });

  return (
    <div className="SignUp">
      <div className="sign-up-wrapper">
        <Link to="/">
          <img src={logo} width={150} alt="logo" />
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <h3>Register now to continue</h3>
          <p>
            Sign in to view your LKP account. If you don't have one, you will be
            prompted to create one.
          </p>
          <div className="input-group">
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              value={formik.values.fname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.fname && formik.errors.fname
                  ? "invalid-input"
                  : ""
              }
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              value={formik.values.lname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.lname && formik.errors.lname
                  ? "invalid-input"
                  : ""
              }
            />
          </div>

          <div className="input-group">
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.email && formik.errors.email
                  ? "invalid-input"
                  : ""
              }
            />
            <input
              type="text"
              placeholder="Phone Number"
              name="phone_number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.phone_number && formik.errors.phone_number
                  ? "invalid-input"
                  : ""
              }
            />
          </div>
          <h4>Account Type</h4>
          <div id="radio-group">
            <div className="radio-option">
              <input
                type="radio"
                name="user_role"
                value="USER"
                checked={formik.values.user_role === "USER"}
                onChange={formik.handleChange}
              />
              <label>User</label>
            </div>
            <div className="radio-option">
              <input
                type="radio"
                name="user_role"
                value="AUTHOR"
                checked={formik.values.user_role === "AUTHOR"}
                onChange={formik.handleChange}
              />
              <label>Author</label>
            </div>
          </div>

          <input
            type="password"
            placeholder="Password"
            name="_password_hash"
            value={formik.values._password_hash}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched._password_hash && formik.errors._password_hash
                ? "invalid-input"
                : ""
            }
          />
          <ReCAPTCHA
            sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
            onChange={(val) => setCapVal(val)}
          />
          <button type="submit" disabled={!capVal}>
            REGISTER
          </button>
          <p>
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
