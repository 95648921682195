import React from "react";
import logo from "../../assets/LEFTKOAST-LOGO.png";
import "./LoadingPage.css";

function LoadingPage() {
  return (
    <div className="LoadingPage">
      <div className="loading-page-wrapper">
        <img src={logo} width={300} />
      </div>
    </div>
  );
}

export default LoadingPage;
